import { UserState } from 'app/shared/models';
import { Actions as UserStateActions, Type as UserStateActionType } from 'app/state/actions/user-state.actions';

const initialState: UserState = {
  companyParameters: []
};
export function reducer(state: UserState = initialState, action: UserStateActions) {
  switch (action.type) {
    case UserStateActionType.LOAD_USER_STATE_SUCCESS: {
      return Object.assign({}, action.payload);
    }
    case UserStateActionType.UPDATE_USER_STATE_SUCCESS: {
      return Object.assign({}, action.payload);
    }
    default: {
      return state;
    }
  }
}
