import { Direction } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IgxOverlayOutletDirective } from '@infragistics/igniteui-angular';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AuthenticationService, ConfigurationService } from 'app/shared';
import { NeedsToUnsubscribe } from 'app/shared/core';
import { LoadUserInfo } from 'app/state/actions/user-info.actions';
import { AppState } from 'app/state/app.state';
import { filter, takeUntil } from 'rxjs/operators';
import { ApplicationInsightService } from './shared/errors/application-insights.service';
import { IgxGlobalOverlayService } from './shared/services/igx-global-overlay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends NeedsToUnsubscribe implements OnInit {
  private currentUserLanguage: string;
  private userInfo$ = this.store.select(state => state.userInfo);
  private keysPressed = [];
  buildInfo = '';
  buildInfoVisible = false;
  loadingCurrent = false;
  currentThemeIdx = 0;
  dir: Direction = 'ltr';

  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    this.keysPressed.push(event.key);
    this.keysPressed = this.keysPressed.slice(-3);
    switch (this.keysPressed.join(';')) {
      case 'Escape;Escape;Escape':
        this.toggleBuildinfo();
        break;
      default:
        break;
    }
  }

  @ViewChild(IgxOverlayOutletDirective) set overlay(value: IgxOverlayOutletDirective) {
    this.igxGlobalOverlay.register(value);
  }

  constructor(
    public router: Router,
    private configService: ConfigurationService,
    private translate: TranslocoService,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private igxGlobalOverlay: IgxGlobalOverlayService,
    @Inject(DOCUMENT) private _document: Document,
    applicationInsights: ApplicationInsightService
  ) {
    super();
    applicationInsights.trackPageView();
  }
  ngOnInit(): void {
    this.translate.setDefaultLang('en');

    this.authService.userLoadededEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        if (this.authService.loggedIn && this.currentUserLanguage == null && !this.loadingCurrent) {
          this.loadingCurrent = true;
          this.store.dispatch(new LoadUserInfo());
        }
      });


    this.userInfo$
      .pipe(takeUntil(this.unsubscribe$),
        filter(u => u !== null))
      .subscribe(user => {
        if (user.userId != null) {
          this.currentUserLanguage = user.languageCode;
          this.setI18NAttributes(user.languageCode, user.rtl ? 'rtl' : 'ltr');
          if (user.status === 0) {
            this.authService.startSignoutMainWindow();
          }
          this.loadingCurrent = false;
        }
      });


    this.store.select(s => s.surveyState)
      .subscribe(surveyState => {
        this.setI18NAttributes(surveyState.languageCode, surveyState.languageDir);
      })
  }

  private setI18NAttributes(lang: string, dir: Direction) {
    this.renderer.setAttribute(
      this._document.documentElement, 'lang', lang
    );
    this.dir = dir;
    this.renderer.setAttribute(
      this._document.documentElement, 'dir', this.dir
    );
  }

  toggleBuildinfo() {
    const config = this.configService.configuration;
    this.buildInfo = config.BuildName === '!Local'
      ? 'Local build'
      : `
                ${config.BuildName}, Build: ${config.BuildVersion}
                 source branch [${config.BuildBranch}]
                `;
    this.buildInfoVisible = !this.buildInfoVisible;
    this.keysPressed = [];
  }

}
