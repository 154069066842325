import { CompanyContext } from 'app/shared/models';
import { CompanyComment } from 'app/shared/models/company/company-comment-model';
import {
  Actions as CompanyContextActions,
  Type as CompanyContextActionType,
} from 'app/state/actions/company-context.actions';

export const initialState: CompanyContext = {
    id: null,
    name: null,
    shortName: null,
    internalName: null,
    branch: null,
    detailedBranch: null,
    companyLanguages: [],
    companyAttributes: [],
    companyComments: [],
    restrictedActivationCommunication: false,
    smsEnabled: false,
    pinEnabled: false,
    isActive: false,
    mySurveysEnabled: false,
    userExternalIdIsMandatory: false,
    branding: null,
    accountName: null,
    type: null,
    segmentsHierarchyId: null
};

export function reducer(state: CompanyContext = initialState, action: CompanyContextActions): CompanyContext {

  switch (action.type) {
    case CompanyContextActionType.SET_COMPANY_CONTEXT_SUCCESS: {
      return { ...action.payload };
    }
    case CompanyContextActionType.UPDATE_COMPANY_COMMENT_CONTEXT:
    case CompanyContextActionType.DELETE_COMPANY_COMMENT_CONTEXT:
    case CompanyContextActionType.ADD_COMPANY_COMMENT_CONTEXT: {
      return {
        ...state,
        companyComments: commentsReducer(state.companyComments, action)
      }
    }
    case CompanyContextActionType.SAVE_COMPANY_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        companyAttributes:action.payload

      }
    }
    default: {
      return state;
    }
  }
}


const initialStateComments: CompanyComment[] = []

function commentsReducer(state = initialStateComments, action: CompanyContextActions) {
  switch (action.type) {
    case CompanyContextActionType.ADD_COMPANY_COMMENT_CONTEXT: {
      return [...state, action.payload]
    }
    case CompanyContextActionType.UPDATE_COMPANY_COMMENT_CONTEXT: {
      return state.map(comment => {
        if (comment.id !== action.payload.id) {
          return comment;
        }

        return {
          ...comment,
          comment: action.payload.comment
        }
      });
    }
    case CompanyContextActionType.DELETE_COMPANY_COMMENT_CONTEXT: {
      return [...state].filter(item => item.id !== action.payload);
    }
    default: {
      return state;
    }
  }
}
