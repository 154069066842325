interface Array<T> {
  distinct(): T[];
  remove(item: T): boolean;
  removeEvery(predicate: (item: T) => boolean): void;
  /**
   * @deprecated Use `arr.flatMap(predicate)` instead
   */
  selectMany<U>(predicate: (item: T) => U[]): U[];
  groupBy(func: (x: T) => string): Grouping<T>[];
  equals(e: T[]): boolean;
}
interface Grouping<T> {
  key: string;
  values: T[];
}
(function <T>() {

  if (!Array.prototype.equals) {
    Array.prototype.equals = function (this: T[], b: T[]) {
      if (this.length !== b.length) { return false; }
      const uniqueValues = new Set([...this, ...b]);
      for (const v of uniqueValues) {
        const aCount = this.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) { return false; }
      }
      return true;
    };
  }
  if (!Array.prototype.distinct) {
    Array.prototype.distinct = function (this: T[]) {
      return this.filter((v, i, a) => a.indexOf(v) === i);
    };
  }

  if (!Array.prototype.remove) {
    Array.prototype.remove = function (this: T[], item: T): boolean {
      const index = this.indexOf(item);
      if (index >= 0) {
        this.splice(index, 1);
        return true;
      }
      return false;
    };
  }

  if (!Array.prototype.removeEvery) {
    Array.prototype.removeEvery = function (this: T[], predicate: (item: T) => boolean) {
      const items = this.filter(x => predicate(x));
      for (const item of items) {
        this.remove(item);
      }
    };
  }

  if (!Array.prototype.selectMany) {
    Array.prototype.selectMany = function <U>(this: T[], predicate: (item: T) => U[]) {
      return this.map(predicate).reduce((a, b) => a.concat(b), []);
    };
  }

  if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function (this: T[], keyFunction: (item: T) => string): Grouping<T>[] {
      const groups: { [key: string]: T[] } = {};
      this.forEach(function (el) {
        const key = keyFunction(el);
        // eslint-disable-next-line eqeqeq
        if (key in groups == false) {
          groups[key] = [];
        }
        groups[key].push(el);
      });
      return Object.keys(groups).map(key => ({
        key: key,
        values: groups[key]
      }));
    };
  }
})();
