import { Configuration } from 'app/shared/models';
import { Actions as ConfigurationActions, Type as ConfigurationActionType } from 'app/state/actions/configuration.actions';

const initialState: Configuration = {
  ApiUrl: null,
  AuthUrl: null,
  LocalUrl: null,
  ChrossApiUrl: null,
  BenchmarkApiUrl: null,
  ExHelpCenterBaseUrl: null,
  CxHelpCenterBaseUrl: null,
  ExPendoAPIKey: null,
  CxPendoAPIKey: null,
  WPApiUrl: null,
  InstrumentationKey: null,
  BuildVersion: null,
  BuildBranch: null,
  BuildName: null,
  Environment: null,
  FeatureFlags: null,
  BrilliantHomePageBaseUrl: null
};

export function reducer(state: Configuration = null, action: ConfigurationActions) {
  switch (action.type) {
    case ConfigurationActionType.LOAD_CONFIGURATION_SUCCESS: {
      return Object.assign({}, state, {
        ApiUrl: action.payload.API_URL,
        AuthUrl: action.payload.AUTH_URL,
        LocalUrl: action.payload.LOCAL_URL,
        ChrossApiUrl: action.payload.CHROSS_API_URL,
        BenchmarkApiUrl: action.payload.BENCHMARK_API_URL,
        WPApiUrl: action.payload.WP_API_URL,
        BrilliantHomePageBaseUrl: action.payload.BRILLIANTFUTURE_HP_BASE_URL,
        ExHelpCenterBaseUrl: action.payload.EX_HELPCENTER_BASE_URL,
        CxHelpCenterBaseUrl: action.payload.CX_HELPCENTER_BASE_URL,
        InstrumentationKey: action.payload.ApplicationInsights?.InstrumentationKey,
        ExPendoAPIKey: action.payload.EX_PENDO_API_KEY,
        CxPendoAPIKey: action.payload.CX_PENDO_API_KEY,
        BuildBranch: action.payload.BUILD_BRANCH,
        BuildVersion: action.payload.BUILD_VERSION,
        BuildName: action.payload.BUILD_NAME,
        Environment: action.payload.Environment,
        FeatureFlags: action.payload.FeatureFlags
      });
    }
    default: {
      return state;
    }
  }
}
