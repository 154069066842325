import { OrganizationState } from 'app/shared/models';
import { Actions as OrganizationActions, Type } from 'app/state/actions/organization.actions';


const initialState: OrganizationState = {
  organizationTableLoading: false,
  userTableLoading: false,
  deletedGroupsTableLoading: false,
  deletedGroupsCount: 0,
  orphanGroupsTableLoading: false,
  orphanGroupsCount: 0
};

export function reducer(state: OrganizationState = initialState, action: OrganizationActions) {
  switch (action.type) {
    case Type.ORGANIZATION_TREE_TABLE_LOADING:
      return { ...state, organizationTableLoading: true };
    case Type.ORGANIZATION_TREE_TABLE_LOADING_COMPLETE:
      return { ...state, organizationTableLoading: false };
    case Type.USER_TABLE_LOADING:
      return { ...state, userTableLoading: true };
    case Type.USER_TABLE_LOADING_COMPLETE:
      return { ...state, userTableLoading: false };
    case Type.DELETED_GROUPS_TABLE_LOADING:
        return { ...state, deletedGroupsTableLoading: true };
    case Type.DELETED_GROUPS_TABLE_LOADING_COMPLETE:
        return { ...state, deletedGroupsTableLoading: false };
    case Type.SET_DELETED_GROUPS_COUNT:
      return { ...state, deletedGroupsCount: action.payload };
    case Type.ORPHAN_GROUPS_TABLE_LOADING:
      return { ...state, orphanGroupsTableLoading: true };
    case Type.ORPHAN_GROUPS_TABLE_LOADING_COMPLETE:
      return { ...state, orphanGroupsTableLoading: false };
    case Type.SET_ORPHAN_GROUPS_COUNT:
      return { ...state, orphanGroupsCount: action.payload };
    default: {
      return state;
    }
  }
}
