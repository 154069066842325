import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';

const appRoutes: Routes = [
  { path: 'survey', loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule) },
  { path: 'survey-portal', loadChildren: () => import('app/survey-portal/survey-portal.module').then(m => m.SurveyPortalModule) },
  { path: 'opt-out', loadChildren: () => import('app/shared/opt-out/opt-out.module').then(m => m.OptOutModule) },
  { path: '', loadChildren: () => import('app/layout/layout.module').then(m => m.LayoutModule) },
  { path: '', loadChildren: () => import('app/shared/auth/auth.module').then(m => m.AuthModule) },
  { path: '', loadChildren: () => import('app/shared/errors/errors.module').then(m => m.ErrorsModule) },
  { path: '', pathMatch: 'full', redirectTo: 'admin' }
  // { path: '**', redirectTo: 'portal', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: environment.production ? false : false } // <-- set right value for debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
