import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { DatePipe, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxExcelExporterService, IgxToggleModule } from '@infragistics/igniteui-angular';
import { EffectsModule } from '@ngrx/effects';
import { MinimalRouterStateSerializer, RouterState, StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfigurationService, ErrorsHandler } from 'app/shared';
import { AuthModule } from 'app/shared/auth/auth.module';
import { CoreModule } from 'app/shared/core/core.module';
import { AppState } from 'app/state/app.state';
import {
  CompanyContextEffects, ConfigurationEffects,
  FollowupWizardEffects, NavigationEffects, NavtreeEffects,
  ProductWizardEffects, ReportEffects, SurveyUIEffects, SurveyWizardEffects,
  UserInfoEffects, UserStateEffects
} from 'app/state/effects';
import * as companyContext from 'app/state/reducers/company-context.reducer';
import * as configuration from 'app/state/reducers/configuration.reducer';
import * as followupWizard from 'app/state/reducers/followup-wizard.reducer';
import * as navtreeState from 'app/state/reducers/navtree.reducer';
import * as organizationState from 'app/state/reducers/organization.reducer';
import * as reportState from 'app/state/reducers/report.reducer';
import * as surveyState from 'app/state/reducers/survey-ui.reducer';
import * as surveyWizard from 'app/state/reducers/survey-wizard.reducer';
import * as userInfo from 'app/state/reducers/user-info.reducer';
import * as userState from 'app/state/reducers/user-state.reducer';
import { MarkdownModule } from 'ngx-markdown';
import { lastValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import './prototype/array';
import { Configuration } from './shared/models';
import { UIKitDefaultDialogConfig } from './shared/ui-kit/dialog/dialog-config';
import * as ConfigurationActions from './state/actions/configuration.actions';
import { customFallbackStrategy } from './transloco/custom-fallback-strategy';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    StoreModule.forRoot<AppState>({
      router: routerReducer,
      companyContext: companyContext.reducer,
      userInfo: userInfo.reducer,
      configuration: configuration.reducer,
      userState: userState.reducer,
      surveyWizardContext: surveyWizard.reducer,
      followupWizardContext: followupWizard.reducer,
      surveyState: surveyState.reducer,
      navtreeState: navtreeState.reducer,
      reportState: reportState.reducer,
      organizationState: organizationState.reducer
    },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }
    ),
    EffectsModule.forRoot([
      UserInfoEffects,
      CompanyContextEffects,
      NavigationEffects,
      ConfigurationEffects,
      UserStateEffects,
      SurveyWizardEffects,
      FollowupWizardEffects,
      ProductWizardEffects,
      SurveyUIEffects,
      NavtreeEffects,
      ReportEffects
    ]),
    AuthModule,
    StoreDevtoolsModule.instrument(),
    StoreRouterConnectingModule.forRoot({
      serializer: MinimalRouterStateSerializer,
      routerState: RouterState.Minimal,
    }),
    MarkdownModule.forRoot(),
    TranslocoRootModule,
    IgxToggleModule,
    AppRoutingModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: UIKitDefaultDialogConfig, useClass: UIKitDefaultDialogConfig },
    { provide: DEFAULT_DIALOG_CONFIG, useClass: UIKitDefaultDialogConfig },
    customFallbackStrategy,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Store, ConfigurationService],
      useFactory: getUserConfig
    },
    DatePipe,
    Location,
    IgxExcelExporterService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ErrorHandler, useClass: ErrorsHandler }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

}

function getUserConfig(store: Store<AppState>): () => Promise<Configuration> {
  return function (): Promise<Configuration> {
    store.dispatch(new ConfigurationActions.LoadConfiguration());
    return lastValueFrom(store.select(x => x.configuration)
      .pipe(
        filter(c => !!c),
        take(1)
      )
    );
  };
}
