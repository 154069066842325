import { can, UserInfo } from 'app/shared/models';
import { Actions as UserInfoActions, Type as UserInfoActionType } from 'app/state/actions/user-info.actions';
import { cloneDeep } from 'lodash-es';

const initialState: UserInfo = {
  companyId: null,
  companyName: null,
  languageCode: null,
  languageId: null,
  rtl: false,
  status: null,
  userId: null,
  firstName: null,
  lastName: null,
  userName: null,
  userPermission: { isAdmin: false, companyId: null, groupPermissions: null, can: null },
  displayLanguageCode: null,
  displayLanguageId: null,
  availableAccounts: []
};

export function reducer(state: UserInfo = initialState, action: UserInfoActions): UserInfo {

  switch (action.type) {
    case UserInfoActionType.LOAD_USER_INFO_SUCCESS: {
      const userInfoPayload: UserInfo = cloneDeep(action.payload);
      userInfoPayload.userPermission.can = can;
      return {
        ...state,
        ...userInfoPayload,
        displayLanguageId: userInfoPayload.displayLanguageId ?? state.displayLanguageId ?? state.languageId,
        displayLanguageCode: userInfoPayload.languageCode ?? state.displayLanguageCode ?? state.languageCode
      };
    }

    case UserInfoActionType.SET_USER_CONTEXT_LANGUAGE_SUCCESS: {
      return {
        ...state,
        ...{
          displayLanguageCode: action.payload.code ?? state.displayLanguageCode,
          displayLanguageId: action.payload.id ?? state.displayLanguageId
        }
      };
    }

    case UserInfoActionType.SET_USER_LANGUAGE_SUCCESS: {
      return {
        ...state,
        ...{
          languageId: action.payload?.id ?? state.languageId,
          languageCode: action.payload?.code ?? state.languageCode
        }
      }
    }

    default: {
      return state;
    }
  }
}
